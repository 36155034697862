import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { GridRowParams, GridActionsCellItem, GridActionsCellItemProps, GridRenderCellParams } from '@mui/x-data-grid';

import { PATHS } from 'src/navigation';
import { setParams } from 'src/utils';
import emptyUsers from 'src/assets/emptyUsers.svg';
import { loadUsers } from 'src/redux/users/actions';
import { setUserStatus } from 'src/redux/user/actions';
import { selectSetUserStatusSuccess } from 'src/redux/user/selectors';
import { selectMyUsers, selectUsersError, selectUsersLoading } from 'src/redux/users/selectors';
import { Button, H1, Loader, SomethingWentWrongState, Toolbar, UsersTable } from 'src/components';
import { usersColumnVisibilityModel } from 'src/components/UsersTable';
import { UserType } from 'src/types';
import { PermissionEnum } from 'src/shared/enums/permission.enum';
import config from 'src/config';
import { UserStatus } from 'src/shared/enums/userStatus.enum';

import { useTranslation } from 'react-i18next';
import { DashboardWrapper } from '../wrappers';

export default function MyUsers() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const users = useSelector(selectMyUsers);
  const isUsersLoading = useSelector(selectUsersLoading);
  const isUsersLoadingError = useSelector(selectUsersError);
  const setUserStatusSuccess = useSelector(selectSetUserStatusSuccess);
  const canViewUserProfile = config.can([PermissionEnum.VIEW_FULL_USER_PROFILE]) || config.can([PermissionEnum.VIEW_FIELD_USER_PROFILE]);
  const canEditAnyStatus = [PermissionEnum.APPROVE_USER, PermissionEnum.ACTIVATE_USER, PermissionEnum.BLOCK_USER, PermissionEnum.UNBLOCK_USER].some(
    (permission) => config.can(permission),
  );

  const onSetNewStatus = (user: UserType, status: UserStatus) => {
    dispatch(setUserStatus.init({ uuid: user.uuid, status }));
  };

  useEffect(() => {
    if (setUserStatusSuccess) {
      dispatch(loadUsers.init());
    }
  }, [setUserStatusSuccess]);

  useEffect(() => {
    dispatch(loadUsers.init());
  }, []);

  const getActions = (params: GridRowParams<any>): React.ReactElement<GridActionsCellItemProps>[] => {
    const actions: { [key: number]: any } = {
      [UserStatus.DEACTIVATED]: () => [
        config.can(PermissionEnum.APPROVE_USER) ? (
          <GridActionsCellItem key="approveCell" label="Approve" onClick={() => onSetNewStatus(params.row, UserStatus.APPROVED)} showInMenu />
        ) : null,
        config.can(PermissionEnum.BLOCK_USER) ? (
          <GridActionsCellItem key="blockCell" label="Block" onClick={() => onSetNewStatus(params.row, UserStatus.BLOCKED)} showInMenu />
        ) : null,
      ],
      [UserStatus.APPROVED]: () => [
        config.can(PermissionEnum.ACTIVATE_USER) ? (
          <GridActionsCellItem key="activateCell" label="Activate" onClick={() => onSetNewStatus(params.row, UserStatus.ACTIVE)} showInMenu />
        ) : null,
        config.can(PermissionEnum.BLOCK_USER) ? (
          <GridActionsCellItem key="blockCell" label="Block" onClick={() => onSetNewStatus(params.row, UserStatus.BLOCKED)} showInMenu />
        ) : null,
      ],
      [UserStatus.ACTIVE]: () => [
        config.can(PermissionEnum.BLOCK_USER) ? (
          <GridActionsCellItem key="blockCell" label="Block" onClick={() => onSetNewStatus(params.row, UserStatus.BLOCKED)} showInMenu />
        ) : null,
      ],
      [UserStatus.BLOCKED]: () => [
        config.can(PermissionEnum.UNBLOCK_USER) ? (
          <GridActionsCellItem key="unblockCell" label="Unblock" onClick={() => onSetNewStatus(params.row, UserStatus.DEACTIVATED)} showInMenu />
        ) : null,
      ],
    };

    return actions[params.row.status]().filter((item: any) => item !== null) ?? [];
  };

  const navigateToUserProfile = (params: GridRenderCellParams) => navigate(setParams(PATHS.USER_PROFILE_PAGE, { uuid: params.row.uuid }));

  const columnsVisibility = {
    ...usersColumnVisibilityModel,
    firstName: true,
    lastName: true,
    clearStatus: true,
    userRoles: true,
    verificationStatus: true,
    status: true,
  };

  if (isUsersLoadingError) {
    return (
      <DashboardWrapper breadcrumbs={[[t('usersPage.userManagement')]]}>
        <SomethingWentWrongState />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper
      breadcrumbs={[[t('usersPage.userManagement')]]}
      navigationChildren={
        <>
          {users?.length ? <Toolbar options="mini" /> : null}
          {config.can(PermissionEnum.CREATE_USER) ? (
            <Button disabled sx={{ p: 0, minWidth: '48px', fontSize: '24px' }}>
              +
            </Button>
          ) : null}
        </>
      }
    >
      {isUsersLoading ? (
        <Loader />
      ) : users?.length ? (
        <Box component="main" sx={{ height: 'calc(100vh - 100px)', pt: 4 }}>
          <UsersTable
            users={users}
            columnVisibilityModel={columnsVisibility}
            getActions={canEditAnyStatus ? getActions : undefined}
            toolbarProps={{ options: 'mini' }}
            goTo={canViewUserProfile ? navigateToUserProfile : undefined}
          />
        </Box>
      ) : (
        <Box
          sx={{
            mt: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ ml: 1 }}>
            <img src={emptyUsers} alt="Empty list" />
          </Box>
          <H1 sx={{ mb: 3 }}>{t('usersPage.noUsers')}</H1>
        </Box>
      )}
    </DashboardWrapper>
  );
}
